import { createStore, createStyleSet } from 'botframework-webchat';
import React from 'react';

import './fabric-icons-inline.css';
import './MinimizableWebChat.css';

import WebChat from './WebChat';

export default class extends React.Component {
	
	constructor(props) {
		super(props);
		this.handleFetchToken = this.handleFetchToken.bind(this);
		this.handleMaximizeButtonClick = this.handleMaximizeButtonClick.bind(this);
		this.handleMinimizeButtonClick = this.handleMinimizeButtonClick.bind(this);
		this.handleSwitchButtonClick = this.handleSwitchButtonClick.bind(this);
		
		const store = createStore({}, ({dispatch}) => next => action => {
			if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
				/*setTimeout(() => {
					console.log('DIRECT_LINE/CONNECT_FULFILLED fired!');
					console.log('userid:' + this.state.userId);
					dispatch({
						type: 'WEB_CHAT/SEND_EVENT',
						payload: {
							name: 'webchat/join',
							value: {
								language: window.navigator.language,
								
							},
						},
					});
				}, 1000);*/
			} else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
				console.log('DIRECT_LINE/INCOMING_ACTIVITY fired!');
				if (action.payload.activity.from.role === 'bot') {
					this.setState(() => ({newMessage: true}));
				}
			}
			
			return next(action);
		});
		
		this.state = {
			minimized: true,
			newMessage: false,
			side: 'right',
			store,
			styleSet: createStyleSet({
				backgroundColor: 'Transparent',
			}),
			entityId: 0,
			byTimer: false,
		};
		
		setTimeout(() => {
			this.setState(() => ({
				minimized: false,
				newMessage: false,
				byTimer: true,
			}));
		}, 30000);
	}
	
	async handleFetchToken() {
		// if (!this.state.token) {
		//todo: change to needed token
		//and pass needed groupId, inject it
		//  const res = await fetch('https://webchat-mockbot.azurewebsites.net/directline/token', { method: 'POST' });
		
		//   const { token } = await res.json();
		// const { token } = "IyyZMeznl70.dAA.RQBRADUARgBoAGQAVAB0AFQAOABuAEMAeABzAHcAUABwAGwANAAzAEIAVAAtAGEA.Jtta4v8E1QE.8qQf6jlu_5I.rqkHj0oeItP4Ymi6vhsImZhW8mgxZOcFSjh7GO9_F5A";
		// console.log('http://localhost:3978/botview/GetChatConfig?groupid=5' );
		
		console.log('byTimer' + this.state.byTimer);
		console.log('custom var ' + window.botInstanceId);
		console.log('custom var ' + window.botConfigUri);
		
		//   console.log('https://wabs-ags3-devel-botservice.azurewebsites.net/botview/GetChatConfig?groupid=');
		if (!this.state.token) {
			const res = await fetch(window.botConfigUri + window.botInstanceId + '&byTimer=' + this.state.byTimer, {method: 'GET'});
			//  const res = await fetch('https://tinybot.azurewebsites.net/BotView/GetChatConfig?groupid=4', { method: 'GET' });
			const ress = await res.json();
			const token = ress.token;
			let userId = ress.userId;
			// let byTimer = this.state.byTimer;
			
			this.setState({
				token: token,
				userId: userId
			})
		}
	}
	
	handleMaximizeButtonClick() {
		this.setState(() => ({
			minimized: false,
			newMessage: false,
		}));
	}
	
	handleMinimizeButtonClick() {
		this.setState(() => ({
			minimized: true,
			newMessage: false,
		}));
	}
	
	handleSwitchButtonClick() {
		this.setState(({side}) => ({
			side: side === 'left' ? 'right' : 'left',
		}));
	}
	
	render() {
		const {
			state: {
				minimized,
				newMessage,
				side,
				store,
				styleSet,
				token,
				userId,
				byTimer,
			},
		} = this;
		
		return (
			<div className="minimizable-web-chat">
				{
					minimized ?
						<button
							className="maximize"
							onClick={ this.handleMaximizeButtonClick }
						>
							<span className={ token ? 'ms-Icon ms-Icon--MessageFill' : 'ms-Icon ms-Icon--Message' }/>
							{
								newMessage &&
								<span className="ms-Icon ms-Icon--CircleShapeSolid red-dot"/>
							}
						</button>
						:
						<div
							className={ side === 'left' ? 'chat-box left' : 'chat-box right' }
						>
							<header>
								<div className="filler"/>
								<button
									className="switch"
									onClick={ this.handleSwitchButtonClick }
									// toggle whether the chat is on the left or right
								>
									<span className="ms-Icon ms-Icon--Switch"/>
								</button>
								<button
									className="minimize"
									onClick={ this.handleMinimizeButtonClick }
									// toggle minimized state
								>
									<span className="ms-Icon ms-Icon--ChromeMinimize"/>
								</button>
							</header>
							<WebChat
								className="react-web-chat"
								store={ store }
								styleSet={ styleSet }
								token={ token }
								userId={ userId }
								onFetchToken={ this.handleFetchToken }
							/>
						</div>
				}
			</div>
			: ""
		);
	}
}